import { googleTracker } from "./track";
import { GTMItem } from "../types";

const trackPageView = () => {
  googleTracker.trackEvent({
    eventName: "page_view",
  });
};

const trackViewItem = (item: GTMItem) => {
  googleTracker.trackEcommerceEvent({
    eventName: "view_item",
    eventProperties: {
      value: item.price,
      items: [item],
    },
  });
};

const trackAddToCart = (item: GTMItem) => {
  googleTracker.trackEcommerceEvent({
    eventName: "add_to_cart",
    eventProperties: {
      value: item.price,
      items: [item],
    },
  });
};

const trackViewCart = (items: GTMItem[]) => {
  googleTracker.trackEcommerceEvent({
    eventName: "view_cart",
    eventProperties: {
      value: calculateValue(items),
      items,
    },
  });
};

const trackRemoveFromCart = (item: GTMItem) => {
  googleTracker.trackEcommerceEvent({
    eventName: "remove_from_cart",
    eventProperties: {
      value: item.price,
      items: [item],
    },
  });
};

const trackBeginCheckout = (items: GTMItem[]) => {
  googleTracker.trackEcommerceEvent({
    eventName: "begin_checkout",
    eventProperties: {
      value: calculateValue(items),
      items,
    },
  });
};

type TrackPurchaseInput = {
  coupon?: string;
  shipping?: number;
  transactionId: string;
  items: GTMItem[];
};

const trackPurchase = ({ transactionId, items, coupon, shipping = 40 }: TrackPurchaseInput) => {
  googleTracker.trackEcommerceEvent({
    eventName: "purchase",
    eventProperties: {
      value: calculateValue(items),
      transaction_id: transactionId,
      shipping,
      coupon,
      items,
    },
  });
};

/**
 * Customization events
 */
const trackSelectCategory = (category: string) => {
  googleTracker.trackEvent({
    eventName: "select_category",
    eventProperties: {
      category,
    },
  });
};

const trackSelectStyle = (style: string) => {
  googleTracker.trackEvent({
    eventName: "select_style",
    eventProperties: {
      style,
    },
  });
};

const trackSelectFabric = (fabric: string) => {
  googleTracker.trackEvent({
    eventName: "select_fabric",
    eventProperties: {
      fabric,
    },
  });
};

type TrackSelectCustomizationOptionInput = {
  customization: string;
  customizationOption: string;
};

const trackSelectCustomizationOption = ({
  customization,
  customizationOption,
}: TrackSelectCustomizationOptionInput) => {
  googleTracker.trackEvent({
    eventName: "select_customization_option",
    eventProperties: {
      customization,
      customization_option: customizationOption,
    },
  });
};

const trackHowToMeasure = (measurement: string) => {
  googleTracker.trackEvent({
    eventName: "how_to_measure",
    eventProperties: {
      measurement,
    },
  });
};

export const gtmEvents = {
  trackPageView,
  trackViewItem,
  trackAddToCart,
  trackViewCart,
  trackRemoveFromCart,
  trackBeginCheckout,
  trackPurchase,
  trackSelectCategory,
  trackSelectStyle,
  trackSelectFabric,
  trackSelectCustomizationOption,
  trackHowToMeasure,
};

const calculateValue = (items: GTMItem[]) => {
  return items.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
};
