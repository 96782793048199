import { GoogleTracker } from "../types";

export const googleTracker: GoogleTracker = {
  initialize() {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
    }
  },

  trackEcommerceEvent({ eventName, eventProperties }) {
    if (process.env.NODE_ENV !== "production") {
      console.log("GTM Event:", eventName, eventProperties);
    }

    if (typeof window !== "undefined") {
      if (!window.dataLayer) window.dataLayer = [];

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: eventName,
        ecommerce: {
          currency: "EGP",
          value: eventProperties?.value,
          items: eventProperties?.items,
        },
      });
    }
  },

  trackEvent({ eventName, eventProperties }) {
    if (process.env.NODE_ENV !== "production") {
      console.log("GTM  Event:", eventName, eventProperties);
    }

    if (typeof window !== "undefined") {
      if (!window.dataLayer) window.dataLayer = [];

      window.dataLayer.push({
        event: eventName,
        properties: eventProperties,
      });
    }
  },
};
