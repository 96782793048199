"use client";

import { Provider as JotaiProvider } from "jotai";
import { DevTools as JotaiDevtools } from "jotai-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
export default function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  const [queryClient] = useState(() => new QueryClient());
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="Providers" data-sentry-source-file="index.tsx">
      <JotaiProvider data-sentry-element="JotaiProvider" data-sentry-source-file="index.tsx">
        {children}
        {/* <JotaiDevtools />
         <ReactQueryDevtools initialIsOpen={false} /> */}
      </JotaiProvider>
    </QueryClientProvider>;
}