"use client";

import { Fragment, useEffect } from "react";
import Script from "next/script";
import { usePathname, useSearchParams } from "next/navigation";
import { gtmEvents } from "../../services/analytics";
type GoogleAnalyticsProps = {
  id: string;
};
const GoogleAnalytics = ({
  id
}: GoogleAnalyticsProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    gtmEvents.trackPageView();
  }, [pathname, searchParams]);
  if (process.env.NODE_ENV !== "production") {
    return null;
  }
  return <Fragment>
      <Script id="gtm-script" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${id}');`
    }} data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
      <noscript>
        <iframe src={`https://www.googletagmanager.com/ns.html?id=${id}`} height="0" width="0" style={{
        display: "none",
        visibility: "hidden"
      }} />
      </noscript>
    </Fragment>;
};
export default GoogleAnalytics;